
import { defineComponent, onMounted, ref, watch } from "vue";
import { modalHideListener } from "@/core/helpers/dom";
import { ApiFulfillmentOrders } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { CommonHtmlType } from "@/core/directive/type/common";
import { FulfillmentOrderItemDetailTable } from "@/core/directive/interface/fulfillmentOrder";
import { formatDateTime } from "@/core/directive/function/common";

export default defineComponent({
  name: "show-fulfillment-order-item-detail-modal",
  props: {
    itemId: { type: Number, required: true },
  },
  emits: ["reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const showFulfillmentOrderItemDetailRef = ref<CommonHtmlType>(null);

    const { showServerErrorMsg } = mixin();

    const formData = ref({
      item_id: props.itemId,
      items: [] as FulfillmentOrderItemDetailTable[],
    });

    watch(
      () => props.itemId,
      (newValue) => {
        if (newValue) {
          getShowInfo();
        } else {
          resetForm();
        }
      }
    );

    const getShowInfo = async () => {
      loading.value = true;
      const { data } = await ApiFulfillmentOrders.getFulfillmentOrderItemDetail(
        {
          item_id: props.itemId,
        }
      );
      loading.value = false;
      if (data.code == 0) {
        formData.value.items = data.data.items;
      } else {
        showServerErrorMsg(data);
      }
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      formData.value.items = [];
    };

    onMounted(() => {
      modalHideListener(showFulfillmentOrderItemDetailRef.value, () => {
        resetForm();
        emit("reset-form");
      });
    });

    return {
      t,
      formatDateTime,
      loading,
      formData,
      formRef,
      showFulfillmentOrderItemDetailRef,
    };
  },
});
