/*
 * @Descripttion:
 * @Author: xiang.gao
 * @Date: 2022-05-31 18:05:56
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2022-11-19 15:00:01
 */
import { CommonDateType, CommonIdType, NumberOrString } from "../type/common";
import {
  SalesOrderProfillingDistribution,
  salesOrderProfillingFormData,
  SalesOrderProfillingFulfillment,
  SalesOrderProfillingPim,
  SalesOrderProfillingPriorityManagement,
  SalesOrderProfillingSettlementFraudControlling,
} from "./salesOrder";

export const fulfillmentOrderOverviewData: FulfillmentOrderOverview = {
  id: 0,
  distribution_profile: salesOrderProfillingFormData.distribution,
  priority_management: salesOrderProfillingFormData.priority_management,
  pim_profile: salesOrderProfillingFormData.pim,
  fulfillment: {
    consignee_address: "",
    delivery_type: "",
    order_specification: salesOrderProfillingFormData.fulfillment,
    sale_order_number: "",
  },
  settlement: salesOrderProfillingFormData.settlement_fraud_controlling,
  fulfillment_order_identifier: {
    estimated_delay_days: 0,
    status: 0,
    status_label: "",
    fulfillment_order_number: "",
    external_order_number: "",
    sale_order_number: "",
    id: 0,
    created_at: "",
    merchant: 0,
    warehouse: 0,
    inventory_type: "",
    fulfillment_type: 0,
  },
  merchant_profile: {
    estimated_delivery_duration: "",
    id: 0,
    merchant: "",
    merchant_country: "",
    channel: "",
  },
  fulfilled_items: [],
};

export interface FulfillmentOrderTable {
  order_number: string;
  order_date: CommonDateType;
  order_type: number;
  status: string;
  sales_channel: string;
  bill_to: string;
  ship_to: string;
  grand_total_base: string;
  grand_total_purchase: string;
  closeDisabled: boolean;
}

export interface FulfillmentOrderFilter {
  fulfillment_order_number: string;
  status: string;
  merchant: string;
  channel: string[];
  sale_order_number: string;
  external_order_number: string;
  created_at_start: string;
  created_at_end: string;
}

export interface FulfillmentOrderOverview {
  id: number;
  distribution_profile: SalesOrderProfillingDistribution;
  priority_management: SalesOrderProfillingPriorityManagement;
  pim_profile: SalesOrderProfillingPim;
  fulfillment: FulfillmentOrderOverviewFulfillment;
  settlement: SalesOrderProfillingSettlementFraudControlling;
  fulfillment_order_identifier: FulfillmentOrderOverviewFulfillmentOrderIdentifier;
  merchant_profile: FulfillmentOrderOverviewMerchantProfile;
  fulfilled_items: FulfillmentOrderOverviewFulfilledItem[];
}

interface FulfillmentOrderOverviewPimProfile {
  additional_pim_service: NumberOrString;
  definite_inventory_source: string;
  inventory_notice: string;
  minimum_inventory_request: string;
  inventory_confirmation_date: CommonDateType;
  additional_pim_notice: string;
}

export interface FulfillmentOrderOverviewFulfillment {
  consignee_address: string;
  delivery_type: string;
  // destination: string;
  // destination_location: number[] | DestinationLocation;
  // destination_shipment_id: NumberOrString;
  order_specification: SalesOrderProfillingFulfillment;
  sale_order_number: string;
  // service_provider: NumberOrString;
  // stock_in_shipment_tracking_no: string;
  // pick_up_by: string;
}

export interface DestinationLocation {
  city: string;
  country_iso_2: string;
  id: CommonIdType;
  lac: string;
  name: string;
  relation_id: CommonIdType;
  street: string;
  zip_code: string;
}

interface FulfillmentOrderOverviewFulfillmentOrderIdentifier {
  estimated_delay_days: NumberOrString;
  status: number;
  status_label: string;
  fulfillment_order_number: string;
  external_order_number: string;
  sale_order_number: string;
  id: number;
  created_at: string;
  merchant: NumberOrString;
  warehouse: NumberOrString;
  inventory_type: string;
  fulfillment_type: number;
}

interface FulfillmentOrderOverviewMerchantProfile {
  estimated_delivery_duration: string;
  id: number;
  merchant: string;
  merchant_country: string;
  channel: string;
}

export interface FulfillmentOrderOverviewFulfilledItem {
  id: NumberOrString;
  sku_identifier: string;
  vat_rate: string;
  merchant_distribution_margin_percent: string;
  quantity: NumberOrString;
  stock_in_quantity: NumberOrString;
}

export interface FulfillmentOrderItemDetailTable {
  created_at: string;
  created_uid: number;
  deleted_at: CommonDateType;
  deleted_uid: number;
  fulfillment_order_number: string;
  id: CommonIdType;
  identification_code: string;
  item_id: number;
  sale_order_number: string;
  sku_identifier: string;
  status: number;
  updated_at: string;
  updated_uid: number;
  __show: FulfillmentOrderItemDetailTableShow;
}

interface FulfillmentOrderItemDetailTableShow {
  status: string;
}

export interface Exception {
  order_number: string;
  order_date: CommonDateType;
  order_type: number;
  status: string;
  sales_channel: string;
  bill_to: string;
  ship_to: string;
  grand_total_base: string;
  grand_total_purchase: string;
}

export interface merchantChannelOption {
  channel: merchantChannelData[];
  id: NumberOrString;
  merchant_country: string;
  name: string;
  qty: number;
}

export interface merchantChannelData {
  id: number;
  name: string;
  qty: number;
  key: NumberOrString;
  inventory_owner: number;
  inventory_status: number;
}
