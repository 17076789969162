
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  ApiBase,
  ApiChanel,
  ApiMerchant,
  ApiFulfillmentOrders,
  ApiLogisticsServiceProvider,
} from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import CancelFulfillmentOrderModal from "@/views/mb-order-routing/fulfillment-orders/CancelFulfillmentOrderModal.vue";
import ConfirmFulfillmentOrderModal from "@/views/mb-order-routing/fulfillment-orders/ConfirmFulfillmentOrderModal.vue";
import ShowFulfillmentOrderItemDetailModal from "@/views/mb-order-routing/fulfillment-orders/ShowFulfillmentOrderItemDetail.vue";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";

import {
  ChannelOption,
  MerchantOption,
  TaggingChildrenItem,
  TaggingItem,
  WarehouseOption,
} from "@/core/directive/interface/common";
import {
  fulfillmentOrderOverviewData,
  FulfillmentOrderOverviewFulfilledItem,
} from "@/core/directive/interface/fulfillmentOrder";
import {
  ProductItem,
  ProductItemImage,
  ProductItemOfficialPrices,
} from "@/core/directive/interface/order";

import { FulfillmentOrderStatus } from "@/core/directive/type/fulfillmentOrder";
import {
  CommonSwtich,
  CommonIdType,
  NumberOrString,
  CommonFormType,
  CommonButtonType,
  CommonDropdownSwtich,
  CommonRequiredSwtich,
} from "@/core/directive/type/common";

import {
  commonBackToList,
  commonChangeDefaultDate,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import {
  getOrderProductIdData,
  getOrderOfficialPrices,
  previewOrderImages,
} from "@/core/directive/function/order";
import {
  getFulfillmentSourceRadio,
  getFulfillmentStatus,
} from "@/core/directive/function/fulfillmentOrder";
import {
  getMbAnnualLimitVerificationRadio,
  getPartialShipmentOptions,
  getPartialWholeShipmentOptions,
  getQaReportOptions,
} from "@/core/directive/function/salesOrder";
import { getInfluenceOrderTypeOptions } from "@/core/directive/function/distribution";
import { salesOrderProfillingFormData } from "@/core/directive/interface/salesOrder";
import CommonChannelOption from "@/components/layout/CommonChannelOption.vue";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "fulfillment-order-overview",
  components: {
    CancelFulfillmentOrderModal,
    ConfirmFulfillmentOrderModal,
    ShowFulfillmentOrderItemDetailModal,
    ProductInfo,
    SkuInfo,
    CommonChannelOption,
    PermissionCommon,
  },
  setup(props, context) {
    const { t } = useI18n();

    const currentInstance: any = getCurrentInstance();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(true);
    const formRef = ref<CommonFormType>(null);
    const submitButton = ref<CommonButtonType>(null);
    const closeButton = ref<CommonButtonType>(null);

    const formData = ref(Object.assign({}, fulfillmentOrderOverviewData));

    const rules = ref({
      "merchant_profile.merchant": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "merchant_profile.channel": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      "merchant_profile.estimated_delivery_duration": [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const orderData = ref(Object.assign({}, salesOrderProfillingFormData));

    const options = ref({
      defaultFormData: {
        delivery_type: "",
      },
      CommonSwtich: CommonSwtich,
      CommonDropdownSwtich: CommonDropdownSwtich,
      CommonRequiredSwtich: CommonRequiredSwtich,
      FulfillmentOrderStatus: FulfillmentOrderStatus,
      partial_shipment: getPartialShipmentOptions(t),
      qa_report: getQaReportOptions(t),
      influence_order_type: getInfluenceOrderTypeOptions(t),
      partial_whole_shipment: getPartialWholeShipmentOptions(t),
      tax_duties_terms: [] as TaggingItem[],
      fulfillment_type: getFulfillmentSourceRadio(t),
      fulfillment_order_status: new Map([]) as Map<number, string>,
      additional_notification: [] as TaggingItem[],
      exception_risk_level: [] as TaggingItem[],
      membership_group: [] as TaggingItem[],
      distribution_channel: [] as TaggingChildrenItem[],
      mb_order_type: [] as TaggingItem[],
      mb_fulfillment_type: [] as TaggingChildrenItem[],
      fulfillment_manager: [] as TaggingItem[],
      sales_manager: [] as TaggingItem[],
      offline_payment_controller: [] as TaggingItem[],
      required_documents: [] as TaggingItem[],
      merchantLoading: false,
      merchant: [] as MerchantOption[],
      channel: [] as ChannelOption[],
      channelLoading: false,
      delivery_type: [] as TaggingItem[],
      service_provider: [] as TaggingItem[],
      destination: [] as WarehouseOption[],
      definite_inventory_source: [] as TaggingItem[],
      mb_annual_limit_verification: getMbAnnualLimitVerificationRadio(t),
      invoices: [],
      fulfilled_items: [] as ProductItem[],
      inventory_type: [] as TaggingItem[],
      packaging_service: [] as TaggingItem[],
      photographing_service: [] as TaggingItem[],
      credit_risk_control: [] as TaggingItem[],
      priority_handling: [] as TaggingItem[],
      priority_level: [] as TaggingItem[],
      closeDisabled: false,
      loadingProducts: true,
    });

    const cancelId = ref<CommonIdType>("");
    const editItemDetailId = ref<CommonIdType>("");

    const getLogisticsServiceProviderData = async () => {
      const { data } =
        await ApiLogisticsServiceProvider.getLogisticsServiceProviderData({
          max_item: "all",
        });
      if (data.code == 0) {
        let shipmentProviderOptionsData = data.data;
        let pickUp = {
          label: t("shipments.pickUpBy"),
          value: -1,
        };
        shipmentProviderOptionsData.push(pickUp);

        options.value.service_provider = shipmentProviderOptionsData;
      }
    };

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({
        max_item: "all",
      });
      if (data.code == 0) {
        options.value.destination = data.data;
      }
    };

    const getFulfillmentData = async () => {
      const { data } = await ApiFulfillmentOrders.getFulfillmentOrderData({
        id: route.params.id,
      });
      if (data.code == 0) {
        Object.keys(options.value.defaultFormData).forEach((item) => {
          options.value.defaultFormData[item] = data.data.fulfillment[item];
        });
        let ids: string[] = [];
        data.data.fulfilled_items.map(
          (item: FulfillmentOrderOverviewFulfilledItem) => {
            ids.push(item.sku_identifier);
          }
        );
        getProductsData({
          sku_id: ids,
          merchant_id: 0,
        });
        getMerchantSourceData("", data.data.merchant_profile.merchant);
        getSalesChannelsData(data.data.merchant_profile.merchant);
        if (data.data.distribution_profile.membership_group != "") {
          getGroupLevelList(
            "",
            data.data.distribution_profile.membership_group
          );
        }
        if (data.data.distribution_profile.sales_manager !== "0") {
          getMangersList(0, "", data.data.distribution_profile.sales_manager);
        } else {
          options.value.sales_manager = [
            {
              value: "0",
              label: "用户下单",
            },
          ];
        }
        if (
          data.data.fulfillment.order_specification.fulfillment_manager !== ""
        ) {
          getMangersList(
            1,
            "",
            data.data.fulfillment.order_specification.fulfillment_manager
          );
        }

        if (data.data.settlement.offline_payment_controller !== "") {
          getMangersList(
            2,
            "",
            data.data.settlement.offline_payment_controller
          );
        }
        formData.value = data.data;
      }
    };

    const getGroupLevelList = async (value?: string, id?: string) => {
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };
      const { data } = await ApiBase.getInfluencerGroupSourceData(params);
      if (data.code == 0) {
        options.value.membership_group = data.data;
      }
    };

    const getChannelList = async () => {
      const { data } = await ApiChanel.getDistributionDataSource({});
      if (data.code == 0) {
        options.value.distribution_channel = data.data;
      }
    };

    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: [
          "required_documents_tagging",
          "additional_notification_tagging",
          "exception_risk_level",
          "fulfillment_delivery_type",
          "definite_inventory_source",
          "sku_inventory_type",
          "fulfillment_packaging_service",
          "fulfillment_photographing_service",
          "fulfillment_order_status",
          "customer_clearance_mode",
          "sales_specification_credit_risk_control",
          "sales_order_priority_handling",
          "sales_order_priority_level",
        ],
      });
      if (data.code == 0) {
        options.value.required_documents =
          data.data.required_documents_tagging.items;
        options.value.additional_notification =
          data.data.additional_notification_tagging.items;
        options.value.exception_risk_level =
          data.data.exception_risk_level.items;
        options.value.delivery_type = data.data.fulfillment_delivery_type.items;
        options.value.definite_inventory_source =
          data.data.definite_inventory_source.items;
        options.value.inventory_type = data.data.sku_inventory_type.items;
        options.value.packaging_service =
          data.data.fulfillment_packaging_service.items;
        options.value.photographing_service =
          data.data.fulfillment_photographing_service.items;
        options.value.tax_duties_terms =
          data.data.customer_clearance_mode.items;
        data.data.fulfillment_order_status.items.map((item) => {
          options.value.fulfillment_order_status.set(
            Number(item.value),
            item.label
          );
        });
        options.value.credit_risk_control =
          data.data.sales_specification_credit_risk_control.items;
        options.value.priority_handling =
          data.data.sales_order_priority_handling.items;
        options.value.priority_level =
          data.data.sales_order_priority_level.items;
      }
    };

    const getFulfillmentModeSource = async () => {
      const { data } = await ApiFulfillmentOrders.getFulfillmentModeSource({
        order_id: route.params.id,
      });
      if (data.code == 0) {
        options.value.mb_fulfillment_type = data.data;
      }
    };

    const switchUserValue = (type: number, data: TaggingItem[]) => {
      switch (type) {
        case 0:
          options.value.sales_manager = data;
          break;
        case 1:
          options.value.fulfillment_manager = data;
          break;
        case 2:
          options.value.offline_payment_controller = data;
          break;
        default:
          break;
      }
    };

    const getMangersList = async (type, search?: string, id?: string) => {
      let params =
        search != ""
          ? {
              search_value: search,
              status: 0,
            }
          : {
              id: id,
              status: 0,
            };
      const { data } = await ApiBase.getUserSourceData(params);
      if (data.code == 0) {
        switchUserValue(type, data.data);
      }
    };

    const getMerchantSourceData = async (value, id?) => {
      options.value.merchantLoading = true;
      let params = id
        ? {
            id: id,
          }
        : {
            search_value: value,
          };
      const { data } = await ApiBase.getMerchantSourceData({
        ...params,
        options: { include_fields: ["name", "country_iso_2", "address"] },
      });
      options.value.merchantLoading = false;
      if (data.code == 0) {
        options.value.merchant = data.data;
      }
    };

    const debounceMerchantSourceSearch = _.debounce(
      getMerchantSourceData,
      1000
    );

    const searchMerchantSourceItems = (query: string) => {
      debounceMerchantSourceSearch(query);
    };

    const getSalesChannelsData = async (
      id?: NumberOrString,
      remake?: boolean
    ) => {
      if (remake) {
        formData.value.merchant_profile.channel = "";
      }
      options.value.channelLoading = true;
      const { data } = await ApiMerchant.getSalesChannelsData({
        max_item: "all",
        merchant_id: id,
        status: "10",
      });
      options.value.channelLoading = false;
      if (data.code == 0) {
        options.value.channel = data.data;
      }
    };

    const getProductsData = async (ids) => {
      options.value.loadingProducts = true;
      const { data } = await ApiBase.getProductsData(ids);
      options.value.loadingProducts = false;
      if (data.code == 0) {
        options.value.fulfilled_items = data.data;
      }
    };

    const getProductIdData = computed(() => {
      return (id: NumberOrString) => {
        return getOrderProductIdData(id, options.value.fulfilled_items);
      };
    });

    const getOfficialPrices = computed(() => {
      return (officialPrices: ProductItemOfficialPrices[] | undefined) => {
        return getOrderOfficialPrices(officialPrices);
      };
    });

    const statusClass = (status) => {
      let statusClass = "";
      switch (status) {
        case 1:
          //蓝
          statusClass = "badge-light-primary";
          break;
        case 0:
          // 红
          statusClass = "badge-light-danger";
          break;
      }
      return statusClass;
    };

    const fulfillmentOrderstatusClass = computed(() => {
      return (status: NumberOrString) => {
        return getFulfillmentStatus(status);
      };
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate(async (valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          const { data } =
            await ApiFulfillmentOrders.updateFulfillmentOrderData(
              formData.value
            );
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              getFormInfo();
            });
          } else {
            showServerErrorMsg(data);
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      commonBackToList(router, "/mb-order-routing/fulfillment-orders");
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([
        getWarehouseData(),
        getDropdownOptions(),
        getFulfillmentModeSource(),
        getChannelList(),
        getFulfillmentData(),
        getLogisticsServiceProviderData(),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getFormReset = () => {
      getFormInfo();
      context.emit("getFormData");
    };

    const handleEditItemDetail = (row) => {
      editItemDetailId.value = row.id;
    };

    const handleItemDetail = (row) => {
      editItemDetailId.value = "";
    };

    const cancelItem = () => {
      cancelId.value = route.params.id;
    };

    const closeItem = () => {
      Swal.fire({
        text: t("fulfillmentOrder.closeTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (closeButton.value) {
            closeButton.value.setAttribute("data-kt-indicator", "on");
          }
          options.value.closeDisabled = true;
          const { data } = await ApiFulfillmentOrders.closeFulfillmentOrderData(
            {
              id: route.params.id,
            }
          );
          closeButton.value?.removeAttribute("data-kt-indicator");
          options.value.closeDisabled = false;
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              getFormInfo();
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const getProgress = computed(() => {
      return getFulfillmentStatus(
        formData.value.fulfillment_order_identifier.status
      );
    });

    const setFulfillmentProfileDisabled = computed(() => {
      const profileData = options.value.defaultFormData;
      if (profileData.delivery_type !== "") {
        return true;
      }
      return false;
    });

    onBeforeMount(() => {
      // if (!currentInstance.parent.parent.ctx._.setupState.loading) {
      //   getFormInfo();
      // }
      getFormInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDateTime,
      commonChangeDefaultDate,
      loading,
      formData,
      formRef,
      rules,
      options,
      cancelId,
      editItemDetailId,
      orderData,
      submitButton,
      closeButton,
      getSalesChannelsData,
      fulfillmentOrderstatusClass,
      statusClass,
      submit,
      backToList,
      getProductIdData,
      getOfficialPrices,
      getFormInfo,
      getFormReset,
      handleEditItemDetail,
      handleItemDetail,
      cancelItem,
      closeItem,
      getProgress,
      setFulfillmentProfileDisabled,
      searchMerchantSourceItems,
    };
  },
});
