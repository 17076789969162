import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "modal_show_fulfillment_order_item_detail",
  ref: "showFulfillmentOrderItemDetailRef",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered mw-850px" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = {
  class: "modal-header",
  id: "kt_modal_show_fulfillment_order_item_detail_header"
}
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = {
  "data-bs-dismiss": "modal",
  class: "btn btn-icon btn-sm btn-active-icon-primary"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1" }
const _hoisted_8 = { class: "card-body" }
const _hoisted_9 = { class: "modal-body p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_form = _resolveComponent("el-form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.t("fulfillmentOrder.fulfilledItemDetail")), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createVNode(_component_el_form, {
          model: _ctx.formData,
          ref: "formRef"
        }, {
          default: _withCtx(() => [
            _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_el_table, {
                  height: "400",
                  class: "mb-7",
                  data: _ctx.formData.items,
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      label: _ctx.t('inspectionReport.identificationCode'),
                      prop: "identification_code"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.t('inspectionReport.currentState'),
                      prop: "__show.status"
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      label: _ctx.t('inspectionReport.operationTime')
                    }, {
                      default: _withCtx(({ row }) => [
                        _createTextVNode(_toDisplayString(_ctx.formatDateTime(row.updated_at)), 1)
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["data"])
              ])
            ])), [
              [_directive_loading, _ctx.loading]
            ])
          ]),
          _: 1
        }, 8, ["model"])
      ])
    ])
  ], 512))
}