
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { hideModal } from "@/core/helpers/dom";
import { ApiBase, ApiFulfillmentOrders } from "@/core/api";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import {
  CommonButtonType,
  CommonFormType,
  CommonHtmlType,
  NumberOrString,
} from "@/core/directive/type/common";
import FsLightbox from "fslightbox-vue/v3";
import { FulfillmentOrderOverviewFulfilledItem } from "@/core/directive/interface/fulfillmentOrder";
import {
  ProductItem,
  ProductItemImage,
  ProductItemOfficialPrices,
} from "@/core/directive/interface/order";
import {
  getOrderOfficialPrices,
  getOrderProductIdData,
  previewOrderImages,
} from "@/core/directive/function/order";

export default defineComponent({
  name: "add-exception-handlings-modal",
  props: {
    fulfillment_order_number: { type: String, required: true },
    sale_order_number: { type: String, required: true },
    fulfilled_items: {
      type: Array as () => FulfillmentOrderOverviewFulfilledItem[],
      required: true,
    },
    items: {
      type: Array as () => ProductItem[],
      required: true,
    },
  },
  components: { FsLightbox },
  emits: ["update-list"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const route = useRoute();
    const formRef = ref<CommonFormType>(null);
    const loading = ref<boolean>(false);
    const confirmFulfillmentOrderRef = ref<CommonHtmlType>(null);
    const submitButton = ref<CommonButtonType>(null);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      fulfillment_order_number: props.fulfillment_order_number,
      sale_order_number: props.sale_order_number,
      confirmed_items: [] as any[],
    });

    const rules = ref({});

    const options = ref({
      fulfilledItems: [] as FulfillmentOrderOverviewFulfilledItem[],
      items: [] as ProductItem[],
      loadingProducts: false,
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    watch(
      () => props.fulfillment_order_number,
      (newValue) => {
        formData.value.fulfillment_order_number = newValue;
      }
    );

    watch(
      () => props.sale_order_number,
      (newValue) => {
        formData.value.sale_order_number = newValue;
      }
    );

    watch(
      () => props.fulfilled_items,
      (newValue) => {
        let arr: any[] = [],
          ids: string[] = [];
        newValue.forEach((item: FulfillmentOrderOverviewFulfilledItem) => {
          arr.push({
            id: item.id,
            quantity: item.quantity,
          });
          ids.push(item.sku_identifier);
        });
        options.value.fulfilledItems = newValue;
        formData.value.confirmed_items = arr;
      }
    );

    watch(
      () => props.items,
      (newValue) => {
        options.value.items = newValue;
      }
    );

    const quantityChange = (e, index: number) => {
      if (e === "") {
        formData.value.confirmed_items[index].quantity =
          options.value.fulfilledItems[index].quantity;
      }
    };

    const isDisabledSubmit = computed(() => {
      let isEmpty = false;
      formData.value.confirmed_items.forEach((item) => {
        if (item.quantity === "") {
          isEmpty = true;
        }
      });
      return isEmpty;
    });

    const getProductIdData = computed(() => {
      return (id: NumberOrString) => {
        return getOrderProductIdData(id, options.value.items);
      };
    });

    const getOfficialPrices = computed(() => {
      return (officialPrices: ProductItemOfficialPrices[] | undefined) => {
        return getOrderOfficialPrices(officialPrices);
      };
    });

    const previewImages = (
      previewImages: ProductItemImage[] | undefined,
      imageIndex: number
    ) => {
      options.value.sources = previewOrderImages(previewImages);
      options.value.sourcesIndex = imageIndex;
      options.value.sourcesSlide = imageIndex + 1;
      options.value.toggler = !options.value.toggler;
    };

    const handleDiscard = () => {
      hideModal(confirmFulfillmentOrderRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiFulfillmentOrders.confirmFulfillmentOrder(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  resetForm();
                  hideModal(confirmFulfillmentOrderRef.value);
                  emit("update-list");
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    return {
      t,
      loading,
      formData,
      confirmFulfillmentOrderRef,
      submitButton,
      rules,
      formRef,
      options,
      submit,
      handleDiscard,
      resetForm,
      quantityChange,
      isDisabledSubmit,
      getProductIdData,
      getOfficialPrices,
      previewImages,
    };
  },
});
